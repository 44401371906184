<template>
  <div class="menti-card" :class="{ clickable: to !== null }">
    <component :is="to !== null ? NuxtLink : 'div'" :to="to" class="avatar-container">
      <img v-if="menti.avatar_url" :src="menti.avatar_url" alt="Фото" class="avatar">
      <AvatarPlaceholder :data="menti" v-else class="avatar" />
      <span class="slice slice-1"></span>
      <span class="slice slice-2"></span>
    </component>
    <component :is="to !== null ? NuxtLink : 'div'" :to="to" class="name">{{ fullName }}</component>
  </div>
</template>

<script setup>
import AvatarPlaceholder from '@/components/common/AvatarPlaceholder'
import BaseButton from '@/components/common/BaseButton'
import NuxtLink from '#app/components/nuxt-link'

const props = defineProps({
  to: {
    type: Object,
    default: null
  },
  menti: {
    type: Object,
    required: true
  }
})

const fullName = computed(() => `${props.menti.first_name} ${props.menti.last_name}`)
</script>

<style scoped lang="scss">
.avatar-container {
  height: 347px;
  margin-bottom: 16px;
  border-radius: 10px;
  position: relative;
  display: block;
  & .avatar, & .slice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    object-fit: cover;
    transition: .2s transform;
    z-index: 2;
  }
  & .slice-1 {
    background: var(--color-secondary);
    z-index: 1;
  }
  & .slice-2 {
    background: var(--color-tertiary);
    z-index: 1;
  }
}

.name {
  font-weight: 800;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: .1s color;
}

.price-box {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
  & .note {
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--color-elements-secondary);
  }
  & .price {
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    & span {
      margin-left: 3px;
      color: var(--color-elements-tertiary);
    }
  }
}

.btn {
  margin-top: 16px;
}

.menti-card.clickable {
  & .avatar-container {
    user-select: none;
    cursor: pointer;
    &:hover {
      opacity: 1;
      & .slice-1 {
        transform: translate(-10px, -10px);
      }
      & .slice-2 {
        transform: translate(10px, 10px);
      }
    }
  }
  & .name {
    user-select: none;
    cursor: pointer;
    &:hover {
      opacity: 1;
      color: var(--color-primary);
    }
  }
}
</style>
